<style scoped>
.top {
  height: 50px;
  background: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  box-shadow: inset 0 0 10px 0 hsl(0deg 0% 100% / 41%);
}
</style>
<template>
  <div class="containers">
    <div class="top">
      <span @click="$router.back()">
        <svg
          t="1701932820789"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="12453"
          width="32"
          height="32"
        >
          <path
            d="M510.9 60.7c-245.6 0-446.7 199.8-446.7 446.7C64.2 753 263.9 954 510.8 954s446.6-199.7 446.6-446.6c0.1-245.6-199.6-446.7-446.5-446.7zM760.5 694c-0.1 6.3-5.3 11.4-11.7 11.4-5.6 0-10.3-3.9-11.4-9.3-0.2-0.7-7.8-35.2-40.4-69.1-41.5-43.2-105.8-66.9-186.4-69v93.8c0 4.4-2.5 8.3-6.4 10.3-3.9 2-8.6 1.6-12.2-0.9L268.2 500.7c-1-0.7-1.9-1.6-2.6-2.6-3.7-5.2-2.5-12.3 2.6-16L492 321c3.6-2.6 8.3-2.9 12.2-1 3.9 2 6.3 5.9 6.4 10.3v99.6c23.2 0.9 46.3 4.4 68.7 10.3 46.4 12.2 85 33.4 114.6 63.2 45.6 45.6 68 109.8 66.6 190.6z"
            p-id="12454"
            fill="#ffffff"
          ></path>
        </svg>
      </span>
      <div>
        <span
          @click="
            facingMode == 'facingMode'
              ? (facingMode = 'user')
              : (facingMode = 'facingMode')
          "
        >
          <svg
            t="1702020268956"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="8900"
            width="28"
            height="28"
          >
            <path
              d="M512.030275 205.086691c-14.436956 0-28.259574 2.662134-40.955905 7.576843 1.126287 0.819118 2.150185 1.740626 3.071693 2.662133 17.20148 17.20148 12.79872 49.556644-9.829417 72.184782-19.761224 19.761224-46.894511 25.59744-64.915109 15.358464-0.716728 5.119488-1.126287 10.341366-1.126287 15.768023 0 62.662534 50.887711 113.550245 113.755025 113.550245 62.764924 0 113.755024-50.785321 113.755024-113.550245 0-62.764924-50.990101-113.550245-113.755024-113.550245z"
              fill="#1296db"
              p-id="8901"
            ></path>
            <path
              d="M674.113266 593.246275c93.993801-55.49525 157.168283-157.680232 157.168283-274.609339C831.281549 142.731327 688.345443 0 512.030275 0S192.779 142.731327 192.779 318.739326c0 116.929107 63.072093 219.114089 157.168283 274.609339l-68.089191 117.748225C270.390439 730.858114 284.725005 755.636436 307.660312 755.636436h408.739926c22.935306 0 37.269873-24.778322 25.802219-44.641935-0.10239 0-67.986801-117.748225-68.089191-117.748226zM512.030275 501.812219c-101.365863 0-183.482452-82.014199-183.482452-183.072893 0-101.161084 82.116588-183.072893 183.482452-183.072893 101.365863 0 183.482452 82.014199 183.482451 183.072893S613.396138 501.812219 512.030275 501.812219z"
              fill="#1296db"
              p-id="8902"
            ></path>
            <path
              d="M1015.583119 711.30167c-9.215078-19.454055-21.50185-30.614539-32.764723-38.80572-22.525747-17.406259-44.027597-25.49505-58.362164-31.638436s-22.525747-8.191181-22.525747-8.191181 7.167283 4.09559 20.477952 12.286772c12.286771 8.191181 30.716928 21.39946 48.123187 40.853514 8.191181 9.215078 15.358464 21.39946 19.454055 33.686232 3.071693 12.286771 2.047795 25.49505-5.119488 36.757924-13.310669 23.447255-45.051495 40.853515-78.840116 54.164184-68.60114 25.49505-150.410559 35.734027-221.059494 39.829617-27.645235 2.047795-54.266573 2.047795-77.816218 2.047795v109.352265c27.645235-3.071693 58.362164-8.191181 91.126887-14.334567 75.768423-16.382362 160.649535-40.853515 234.370163-84.778722 18.430157-11.262874 35.836416-23.447255 51.19488-37.781822s28.669133-31.638436 35.836417-53.140286c7.167283-20.477952 5.119488-43.925207-4.095591-60.307569zM397.456132 851.268473c-18.430157 0-37.884212-1.023898-58.362164-2.047795-70.648935-4.09559-152.458354-13.310669-221.059494-38.805719-33.788621-12.286771-65.529447-30.614539-79.864013-53.140286-7.167283-11.262874-9.215078-23.447255-5.119489-36.757925 3.071693-12.286771 10.238976-24.471153 18.430157-33.686231 16.382362-19.454055 34.812519-31.638436 48.123188-40.853515 12.286771-8.191181 20.477952-12.286771 20.477952-12.286771s-8.191181 2.047795-22.525747 8.191181-34.812519 14.334567-58.362164 31.638436c-11.262874 9.215078-23.549645 20.477952-31.740826 36.757924-8.191181 16.382362-10.238976 39.829617-3.071693 60.307569s21.50185 37.781822 36.860314 53.140286c14.334567 14.334567 31.740826 26.518948 50.170983 37.781822 73.720628 43.925207 158.60174 68.39636 234.370163 84.778722 24.573543 5.119488 49.147085 9.215078 71.672833 12.286772v65.427057L530.460432 909.528247 397.353742 795.056494v56.211979z"
              fill="#1296db"
              p-id="8903"
            ></path>
          </svg>
        </span>
      </div>
    </div>
    <live-hw-rtc-server-cmp
      style="width: 100%; height: calc(100vh -50px)"
      :roomId="roomId"
      :facingMode="facingMode"
    ></live-hw-rtc-server-cmp>
  </div>
</template>

<script>
/* eslint-disable */
import liveHwRtcServerCmp from "@/widgets/liveHwRtcServerCmp";

export default {
  name: "Test",
  components: {
    liveHwRtcServerCmp,
  },
  data() {
    return {
      facingMode: "environment",
      roomId: "1234567890qwertyuioplkjhgfdsazxcvbnm",
      // userid: "154710687371852536ca3deccd421c30355b89a52094c8ae0138297",
      // userName: "刘依",
      // signature:
      //   "c1f3af8f8a30ed92497a3ba82f93a62f1972c1e0714f4bf8754865bea74257c0",
      // ctime: 1700665184,
      // webrtc: null,
    };
  },
  methods: {},
  created() {
    // const query = this.$route.query;
    // if (query.roomId) {
    //   this.roomId = query.roomId;
    // }
  },
  async destroyed() {},
};
</script>
