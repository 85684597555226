<template>
  <div class="home" v-if="signature">
    <div style="width: 100%; height: calc(100vh - 50px)" id="mse"></div>
  </div>
</template>

<script >
/* eslint-disable */
import WebRtc from "./webrtc";
import wxLoginApi from "../api/wxLoginApi";
import { wxConPost } from "../api/httpApi";

export default {
  name: "liveHwRtcCmp",

  props: {
    // 内容数据
    facingMode: {
      type: String,
      default: "environment",
    },
    // 内容数据
    roomId: {
      type: String,
      default: "1234567890qwertyuioplkjhgfdsazxcvbnm",
    },
  },
  data() {
    return {
      stream: null,
      userid: "154710687371852536ca3deccd421c30355b89a52094c8ae0138297",
      userName: "刘依",
      signature: "",
      ctime: 1700665184,
      webrtc: null,
      test: "65531a2afa163e598e681505b799e8dd+1234567890qwertyuioplkjhgfdsazxcvbnm+154710687371852536ca3deccd421c30355b89a52094c8ae0138297",
    };
  },
  components: {},
  methods: {
    async initXg() {
      // 加入房间
      await this.webrtc.joinRoom(
        this.userid,
        this.userName,
        this.signature,
        this.ctime,
        0,
        this.roomId
      );
      //  创建本地流并发布
      await this.webrtc.pushStream("mse", this.facingMode);
    },

    async getSign() {
      const result = await wxConPost(
        "/services/gttt-live-mgt/rtcLive/getLiveSign",
        {
          roomId: this.roomId,
        }
      );
      this.signature = result.signature;
      this.ctime = result.ctime;
      this.userid = result.userid;
    },
    async rePushStream() {
      this.webrtc.switchDevice("video");
    },
  },

  watch: {
    facingMode: function (val) {
      this.rePushStream();
    },
  },

  mounted() {},
  async created() {
    // 登录用户
    wxLoginApi.onReady(async () => {
      const _userStr = localStorage.getItem("user");
      if (_userStr) {
        const _user = JSON.parse(_userStr);
        this.userName = _user.name;

        await this.getSign();

        this.webrtc = new WebRtc("65531a2afa163e598e681505b799e8dd");
        this.initXg();
      }
    });
  },
  beforeCreate() {},
  destroyed() {
    this.webrtc.leaveRoom();
  },
};
</script>
<style>
</style>
